import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import handleRequest from '@/utils/helpers/handleRequest.js';
import Api from '@/utils/api/index.js';
import useTransform from 'lodash/transform.js';

const useGlobalStore = defineStore('globalStore', () => {
  const loadingsList = ref([]);
  const provinces = ref([]);
  const citySubscriptions = ref([]);
  const provincesLoading = ref(false);

  const isLoading = computed(() => !!loadingsList.value.length);

  const transformSelect = options =>
    useTransform(
      options,
      (acc, name, value) => {
        acc.push({
          value,
          name,
        });
      },
      [],
    );

  const fetchProvinces = async (force = false) => {
    if (provincesLoading.value || (provinces.value.length && !force)) {
      return;
    }
    provincesLoading.value = true;
    const { isError, data } = await handleRequest(
      Api.get('/location/provinces'),
    );
    if (!isError) {
      provinces.value = transformSelect(data);
    }
    provincesLoading.value = false;
  };

  const toggleLoading = loaded => {
    if (loaded) {
      loadingsList.value.push(true);
      return;
    }
    loadingsList.value.shift();
  };

  const setCitySubscriptions = (data = []) => {
    citySubscriptions.value = data;
  };

  const reset = () => {
    loadingsList.value = [];
    provinces.value = [];
    citySubscriptions.value = [];
  };

  return {
    isLoading,
    loadingsList,
    provinces,
    citySubscriptions,

    fetchProvinces,
    transformSelect,
    setCitySubscriptions,
    toggleLoading,
    reset,
  };
});

export default useGlobalStore;
