<template>
  <div class="w-full relative">
    <label
      v-if="noLabel"
      :for="name"
      :class="[requiredData]"
      class="h-min-5 mb-1 flex items-center gap-2 pl-4 text-base font-medium text-secondary"
    >
      {{ $t(label || '') }}

      <!-- <span v-if="required" class="text-danger dark:text-danger"> *</span> -->
      <span v-if="tooltip" class="flex-1">
        <BaseIcon
          v-tippy="tooltip"
          outlined
          name="info"
          size="24"
          class="text-gray-300 hover:text-primary-blue dark:text-secondary"
        />
      </span>
    </label>

    <div class="relative group">
      <div
        v-if="!noLabel"
        :class="{
          '!items-start': hasValue || isFocus,
        }"
        class="absolute inset-0 flex z-10 pointer-events-none items-center transition ease-in-out delay-150"
      >
        <label
          v-if="!noLabel"
          :for="id"
          class="pl-4 font-medium text-secondary transition"
          :class="[
            hasValue || isFocus ? 'text-xs pt-1' : 'text-base',
            requiredData,
          ]"
        >
          {{ label }}
          <!-- <span v-if="required" class="text-danger dark:text-danger">*</span> -->
        </label>
      </div>
      <Multiselect
        ref="multiselect"
        :value="inputValue"
        :options="options"
        :placeholder="placeholder"
        :searchable="searchable"
        :label="labelKey"
        :allow-absent="allowAbsent"
        :value-prop="valueProp"
        :append-to-body="appendToBody"
        :track-by="labelKey"
        :required="required"
        :mode="mode"
        :name="name"
        :can-clear="canClear"
        :can-deselect="canDeselect"
        :classes="classes"
        :class="[
          errorMessage ? 'border-danger/30' : 'border-gray-100',
          disabled ? 'bg-gray-200 pointer-events-none' : 'bg-gray-100 ',
        ]"
        :disabled="disabled"
        :attrs="{
          onFocus,
          onBlur,
        }"
        @update:model-value="onChange"
      >
        <template v-if="!translate" #tag="{ option }">
          <span
            class="bg-blue-500 text-white text-sm font-semibold py-0.5 pl-2 rounded-2xl mr-1 mb-1 flex items-center whitespace-nowrap rtl:pl-0 rtl:pr-2 rtl:mr-0 rtl:ml-1"
            tabindex="-1"
            aria-label="Subway ❎"
            @click="onRemoveTag(option)"
          >
            <span class="multiselect-tag-wrapper">
              {{ $t(option[labelKey]) }}
            </span>
            <span
              class="flex items-center justify-center p-1 mx-0.5 rounded-sm hover:bg-black hover:bg-opacity-10 group"
            >
              <span
                class="bg-multiselect-remove bg-center bg-no-repeat opacity-30 inline-block w-3 h-3 group-hover:opacity-60"
              ></span>
            </span>
          </span>
        </template>

        <template #singlelabel="{ value }">
          <div class="multiselect-single-label">
            <template v-if="!translate">
              {{ $t(value?.[labelKey] || '') }}
            </template>
            <template v-else>
              {{ value?.[labelKey] || value?.[valueProp] }}
            </template>
          </div>
        </template>
        <template #option="{ option }">
          <template v-if="!translate">
            {{ $t(option?.[labelKey] || '') }}
          </template>
          <template v-else>
            {{ option?.[labelKey] }}
          </template>
        </template>
      </Multiselect>
      <div v-if="!noAlert" class="absolute pointer-events-none">
        <span
          v-if="errorMessage"
          class="alert-error block pl-4 pt-1 text-danger dark:text-danger text-xs font-medium"
        >
          {{ $t(errorMessage || '') }}
        </span>
      </div>
    </div>

    <input type="hidden" :name="name" :value="inputValue" />
  </div>
</template>
<script>
  import { ref, computed } from 'vue';
  import { useField } from 'vee-validate';
  import Multiselect from '@vueform/multiselect';
  import isNil from 'lodash/isNil.js';

  export default {
    name: 'MultiSelect',
    components: { Multiselect },
    props: {
      options: {
        type: Array,
        default: () => [],
      },
      label: String,
      name: String,
      containerClass: String,
      canClear: Boolean,

      id: String,
      placeholder: String,
      modelValue: null,
      mode: {
        type: String,
        default: 'tags',
      },
      errors: {
        type: Object,
        default: () => ({}),
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      required: {
        type: Boolean,
        default: false,
      },
      translate: {
        type: Boolean,
        default: false,
      },
      noAlert: {
        type: Boolean,
        default: false,
      },
      noLabel: {
        type: Boolean,
        default: false,
      },
      searchable: {
        type: Boolean,
        default: false,
      },
      canDeselect: {
        type: Boolean,
        default: true,
      },
      appendToBody: {
        type: Boolean,
        default: false,
      },
      valueProp: {
        type: String,
        default: 'value',
      },
      labelKey: {
        type: String,
        default: 'name',
      },
      enabledControlled: Boolean,
      syncVModel: Boolean,
      allowAbsent: Boolean,
      rules: Object,
    },
    emits: ['update:modelValue', 'change'],
    // methods: {
    //     changeOption(event) {
    //         this.$emit("update:modelValue", event);
    //     },
    // },
    setup(props, { emit }) {
      const multiselect = ref();
      const {
        value: inputValue,
        errorMessage,
        handleChange,
      } = useField(() => props.name || '', props.rules || undefined, {
        initialValue: props.modelValue,
        controlled: !props.enabledControlled,
        syncVModel: props.syncVModel,
      });

      const isFocus = ref(false);

      const hasValue = computed(() => {
        return Array.isArray(inputValue.value)
          ? inputValue.value?.length
          : !isNil(inputValue.value);
      });

      const onChange = val => {
        if (props.disabled) {
          return;
        }
        handleChange(val);
        if (!props.enabledControlled) {
          emit('update:modelValue', val);
        }
        emit('change', val);
      };

      const requiredData = computed(() => {
        if (props.required) {
          return 'after:content-["*"] after:ml-0.5 after:absolute after:text-danger';
        }
        return '';
      });

      const classes = computed(() => {
        return {
          container:
            'relative py-3 text-primary dark:text-gray-300 dark:bg-gray-700 mx-auto w-full flex items-center justify-end box-border cursor-pointer border-2  rounded-2xl  text-base leading-snug outline-none' +
            ' ' +
            props.containerClass,
          containerDisabled: 'cursor-default bg-gray-100',
          containerOpen: 'rounded-b-none',
          containerOpenTop: 'rounded-t-none',
          containerActive: 'ring ring-blue-500 ring-opacity-30',
          singleLabel:
            'flex items-center h-full max-w-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-4 pr-16 box-border rtl:left-auto rtl:right-0 rtl:pl-0 rtl:pr-3.5',
          singleLabelText:
            'overflow-ellipsis overflow-hidden block whitespace-nowrap max-w-full',
          multipleLabel:
            'flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-4 rtl:left-auto rtl:right-0 rtl:pl-0 rtl:pr-3.5 z-10',
          search:
            'w-full absolute inset-0 dark:bg-gray-700 dark:border-gray-600 bg-transparent outline-none focus:ring-0 appearance-none box-border border-0 text-base font-sans rounded-2xl h-full pl-4 rtl:pl-0 rtl:pr-3.5',
          tags: 'flex-grow flex-shrink flex flex-wrap items-center mt-2.5 pl-2 rtl:pl-0 rtl:pr-2 max-h-24 overflow-y-auto',
          tag: 'bg-blue-500 text-white text-sm font-semibold py-0.5 pl-2 rounded-2xl mr-1 mb-1 flex items-center whitespace-nowrap rtl:pl-0 rtl:pr-2 rtl:mr-0 rtl:ml-1',
          tagDisabled: 'pr-2 opacity-50 rtl:pl-2',
          tagRemove:
            'flex items-center justify-center p-1 mx-0.5 rounded-sm hover:bg-black hover:bg-opacity-10 group',
          tagRemoveIcon:
            'bg-multiselect-remove bg-center bg-no-repeat opacity-30 inline-block w-3 h-3 group-hover:opacity-60',
          tagsSearchWrapper:
            'inline-block relative mx-1 mb-1 flex-grow flex-shrink h-full',
          tagsSearch:
            'absolute inset-0 border-0 outline-none focus:ring-0 appearance-none p-0 text-base font-sans box-border w-full bg-gray-100',
          tagsSearchCopy: 'invisible whitespace-pre-wrap inline-block h-px',
          placeholder:
            'flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-4 text-gray-400 rtl:left-auto rtl:right-0 rtl:pl-0 rtl:pr-3.5',
          caret:
            'bg-multiselect-caret bg-center bg-no-repeat w-2.5 h-4 py-px box-content mr-3.5 relative z-10 opacity-40 flex-shrink-0 flex-grow-0 transition-transform transform pointer-events-none rtl:mr-0 rtl:ml-3.5',
          caretOpen: 'rotate-180 pointer-events-auto',
          clear:
            'pr-3.5 relative z-10 opacity-40 transition duration-300 flex-shrink-0 flex-grow-0 flex hover:opacity-80 rtl:pr-0 rtl:pl-4',
          clearIcon:
            'bg-multiselect-remove bg-center bg-no-repeat w-2.5 h-4 py-px box-content inline-block',
          spinner:
            'bg-multiselect-spinner bg-center bg-no-repeat w-4 h-4 z-10 mr-3.5 animate-spin flex-shrink-0 flex-grow-0 rtl:mr-0 rtl:ml-3.5',
          inifite: 'flex items-center justify-center w-full',
          inifiteSpinner:
            'bg-multiselect-spinner bg-center bg-no-repeat w-4 h-4 z-10 animate-spin flex-shrink-0 flex-grow-0 m-3.5',
          dropdown:
            'max-h-60 absolute -left-px -right-px bottom-0 transform translate-y-full border-2 border-gray-300 -mt-px overflow-y-scroll z-50 bg-white dark:bg-gray-700 flex flex-col rounded-b-2xl z-20',
          dropdownTop:
            '-translate-y-full top-px bottom-auto rounded-b-none rounded-t-2xl',
          dropdownHidden: 'hidden',
          options: 'flex flex-col p-0 m-0 list-none',
          optionsTop: '',
          group: 'p-0 m-0',
          groupLabel:
            'flex text-sm box-border items-center justify-start text-left py-1 px-3 font-semibold bg-gray-200 cursor-default leading-normal',
          groupLabelPointable: 'cursor-pointer',
          groupLabelPointed: 'bg-gray-300 text-gray-700',
          groupLabelSelected: 'bg-blue-600 text-white',
          groupLabelDisabled: 'bg-gray-100 text-gray-300 cursor-not-allowed',
          groupLabelSelectedPointed: 'bg-blue-600 text-white opacity-90',
          groupLabelSelectedDisabled:
            'text-blue-100 bg-blue-600 bg-opacity-50 cursor-not-allowed',
          groupOptions: 'p-0 m-0',
          option:
            'flex items-center justify-start box-border text-left cursor-pointer text-base leading-snug py-2 px-3',
          optionPointed: 'text-gray-800 bg-gray-100',
          optionSelected: 'text-white bg-blue-500',
          optionDisabled: 'text-gray-300 cursor-not-allowed',
          optionSelectedPointed: 'text-white bg-blue-500 opacity-90',
          optionSelectedDisabled:
            'text-blue-100 bg-blue-500 bg-opacity-50 cursor-not-allowed',
          noOptions:
            'py-2 px-3 text-gray-600 bg-gray-400 text-left dark:bg-gray-700  ',
          noResults:
            'py-2 px-3 text-gray-600 bg-gray-400 text-left dark:bg-gray-700 ',
          fakeInput:
            'bg-transparent absolute left-0 right-0 -bottom-px w-full h-px border-0 p-0 appearance-none outline-none text-transparent',
          spacer: 'h-9 py-px box-content',
        };
      });

      const onRemoveTag = option => {
        multiselect.value.deselect(option[props.valueProp]);
      };

      const onFocus = () => {
        isFocus.value = true;
      };
      const onBlur = () => {
        isFocus.value = false;
      };

      return {
        isFocus,
        errorMessage,
        inputValue,
        onChange,
        classes,
        hasValue,
        multiselect,
        onRemoveTag,
        requiredData,
        onFocus,
        onBlur,
      };
    },
  };
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style lang="scss">
  // @import "@/assets/select.css";
</style>
