import isFunction from 'lodash/isFunction.js';
import { isUndefined } from 'lodash';

const getResponseData = async rawRes => {
  const res = isFunction(rawRes) ? await rawRes() : await rawRes;
  const data = res?._data || res?.data;
  if (res && Object.keys(res).length !== 1) {
    return res;
  }
  return isUndefined(data) ? res : data;
};

const handleRequest = async req => {
  try {
    const response = await (isFunction(req) ? req() : req);
    return {
      isError: false,
      response,
      data: await getResponseData(response),
    };
  } catch (e) {
    return {
      isError: true,
      response: e,
      data: await getResponseData(e),
    };
  }
};
export default handleRequest;
