import useSubmitQuestionnaire from '@/composables/useQuestionnaireForm.js';
import handleRequest from '@/utils/helpers/handleRequest.js';
import getServerMessage from '@/utils/api/serverErrors.js';
import useGlobalStore from '@/stores/globalStore.js';

export default async to => {
  const { email, token, preview } = to.query;
  if (preview) {
    return;
  }
  if (!email || !token) {
    return '/page-not-found';
  }
  const submitQuestionnaire = useSubmitQuestionnaire();
  const { isError, response, data } = await handleRequest(
    submitQuestionnaire({ email, token }),
  );

  if (isError) {
    return {
      name: 'error',
      query: {
        error: getServerMessage(response, { ignore403: true }),
      },
    };
  }
  useGlobalStore().setCitySubscriptions(data);
};
