import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import API from '@/utils/api';
import ROLES from '@/globals/roles.js';
import handleRequest from '@/utils/helpers/handleRequest.js';
import { useRouter } from 'vue-router';
import useGlobalStore from '@/stores/globalStore.js';

const useAuthStore = defineStore('authStore', () => {
  const router = useRouter();
  const user = ref(null);
  const authLoading = ref(false);
  const companyCharts = ref(null);
  const globalStore = useGlobalStore();

  const isAuth = computed(() => user.value?.id || false);
  const isAdmin = computed(() => ROLES.Admin === user.value?.role);
  const isDashboard = computed(
    () => [ROLES.CityManager, ROLES.Admin].includes(user.value?.role) || false,
  );
  const companyId = computed(() => user.value?.company?.id || null);
  const company = computed(() => user.value?.company || {});
  const reportId = computed(() => user.value?.company?.report?.id || null);
  const isReported = computed(() => user.value?.company?.reported || false);
  const isApproved = computed(() => user.value?.company?.is_approved || false);
  const isCompanySubmitted = computed(
    () => true, //user.value?.company?.submitted || false,
  );
  const isWasPaid = computed(() => user.value?.company?.premium_plan || false);
  const companyData = computed(() =>
    user.value?.company?.id ? user.value?.company : null,
  );
  const getTransports = computed(() => {
    return user.value?.company?.transports;
  });

  const setUser = data => {
    user.value = data;
  };

  const setCompanyData = data => {
    if (user.value?.company) {
      user.value.company = data;
      return;
    }
    user.value['company'] = data;
  };

  const reset = () => {
    user.value = null;
    companyCharts.value = null;
    authLoading.value = false;
  };

  const onSubmitCompany = async () => {
    // Todo: maybe remove this after check is prod
    // const { isError } = handleRequest(
    //   API.put('/companies/' + companyId.value, {}),
    // );
    // if (!isError) {
    //   setCompanyData({
    //     ...user.value.company,
    //     submitted: true,
    //   });
    // }
  };

  const getMe = async () => {
    authLoading.value = true;
    const { isError, data } = await handleRequest(API.get('/me'));
    if (!isError) {
      setUser(data);
    }
    authLoading.value = false;
  };

  const onLogin = async values => {
    const { data, isError } = await handleRequest(async () => {
      await API.csrf();
      return API.post('/login', values);
    });
    if (!isError) {
      setUser(data);

      if (data.role === ROLES.CityManager || data.role === ROLES.Admin) {
        router.push({ name: 'dashboard' });
        return;
      }
      if (company.value.submitted) {
        router.push({ name: 'design' });
        return;
      }
      router.push({ name: 'company.form.one' });
    }
  };

  const onRegister = async values => {
    const { isError, data } = await handleRequest(async () => {
      await API.csrf();
      return API.post('/register', values);
    });
    if (!isError) {
      setUser(data);
      router.push({ name: 'company.form.one' });
    }
  };

  const onResetPassword = async values => {
    const { isError } = await handleRequest(
      API.post('/reset-password', values),
    );
    if (!isError) {
      router.push({ name: 'company.form.one' });
    }
  };

  const onForgotPassword = async values => {
    const { isError } = await handleRequest(
      API.post('/forgot-password', values),
    );
    if (!isError) {
      return true;
    }
  };

  const onLogout = async () => {
    const { isError } = await handleRequest(API.post('/logout'));
    globalStore.reset();

    if (!isError) {
      reset();

      router.push('/');
    }
  };

  const createCompanyQuestionnaireToken = async () => {
    if (companyId.value) {
      const { isError, data } = await handleRequest(
        API.post(`/companies/${companyId.value}/questionnaire-link`),
      );
      if (!isError) {
        user.value.company = data;
      }
    }
  };

  const deleteCompanyQuestionnaireToken = async () => {
    if (companyId.value) {
      const { isError, data } = await handleRequest(
        API.delete(`/companies/${companyId.value}/questionnaire-link`),
      );
      if (!isError) {
        user.value.company = data;
      }
    }
  };

  return {
    company,
    authLoading,
    user,
    isAuth,
    companyId,
    companyData,
    reportId,
    companyCharts,
    isDashboard,
    getTransports,
    isReported,
    isCompanySubmitted,
    isWasPaid,
    isApproved,
    isAdmin,
    createCompanyQuestionnaireToken,
    deleteCompanyQuestionnaireToken,
    getMe,
    setUser,
    onSubmitCompany,
    setCompanyData,
    onLogin,
    onRegister,
    onResetPassword,
    onForgotPassword,
    onLogout,
    reset,
  };
});

export default useAuthStore;
